import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { OnboardingGuard } from './guards/onboarding.guard';
import {RegisterGuard} from './guards/register.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'tabs',
    canActivate: [AuthGuard, RegisterGuard],
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'login',
    canActivate: [OnboardingGuard],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'register-full',
    loadChildren: () => import('./pages/register-full/register-full.module').then(m => m.RegisterFullPageModule)
  },
  {
    path: 'verify',
    loadChildren: () => import('./pages/verify/verify.module').then(m => m.VerifyPageModule)
  },
  {
    path: 'type-select',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/type-select/type-select.module').then( m => m.TypeSelectPageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'profile-edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'job-item',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/job-item/job-item.module').then(m => m.JobItemPageModule)
  },
  {
    path: 'job-post',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/job-post/job-post.module').then(m => m.JobPostPageModule)
  },
  {
    path: 'job-edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/job-edit/job-edit.module').then(m => m.JobEditPageModule)
  },
  {
    path: 'job-post-confirmation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/job-post-confirmation/job-post-confirmation.module').then( m => m.JobPostConfirmationPageModule)
  },
  {
    path: 'categories',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'categories-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/categories-list/categories-list.module').then(m => m.CategoriesListPageModule)
  },
  {
    path: 'profile-edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'job-proposal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/job-proposal/job-proposal.module').then( m => m.JobProposalPageModule)
  },
  {
    path: 'contract-fill',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/contract-fill/contract-fill.module').then( m => m.ContractFillPageModule)
  },
  {
    path: 'my-contracts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/my-contracts/my-contracts.module').then( m => m.MyContractsPageModule)
  },
  {
    path: 'contract-detail',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/contract-detail/contract-detail.module').then( m => m.ContractDetailPageModule)
  },
  {
    path: 'contract-close',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/contract-close/contract-close.module').then( m => m.ContractClosePageModule)
  },
  {
    path: 'messages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'chat/:chatid',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'complaint',
    loadChildren: () => import('./pages/complaint/complaint.module').then( m => m.ComplaintPageModule)
  },
  {
    path: 'images-slides',
    loadChildren: () => import('./pages/images-slides/images-slides.module').then( m => m.ImagesSlidesPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'my-feedbacks',
    loadChildren: () => import('./pages/my-feedbacks/my-feedbacks.module').then( m => m.MyFeedbacksPageModule)
  },
  {
    path: 'feedbacks-list',
    loadChildren: () => import('./pages/feedbacks-list/feedbacks-list.module').then( m => m.FeedbacksListPageModule)
  },
  {
    path: 'user-item',
    loadChildren: () => import('./pages/user-item/user-item.module').then( m => m.UserItemPageModule)
  },
  {
    path: 'coins',
    loadChildren: () => import('./pages/coins/coins.module').then( m => m.CoinsPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
